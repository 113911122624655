import Analytics from "~/src/utils/Analytics.js";
import "./analytics";

$(function () {
  sendGA404Event();
});

function sendGA404Event() {
  var category = "Error";
  var action = "404";
  var label = window.location.pathname;

  if (document.referrer.length > 0) {
    label += [" ref:", document.referrer].join(" ");
  }

  Analytics.event(action, category, label);
}
